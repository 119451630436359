import React from "react";
import { Helmet } from "react-helmet";
import { graphql, PageProps } from "gatsby";
import { RadioTrainingMarkdownRemark } from "types/radio-training-markdown";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/radio-training/Hero";
import Content1 from "../sections/radio-training/Content1";
import Radios from "../sections/radio-training/Radios";
import CTA from "../sections/radio-training/CTA";

// @ts-expect-error false positive image import failure
import imgL1FeatureCurve from "../assets/image/svg/l1-curve-feature.svg";
// @ts-expect-error false positive image import failure
import annotatedImage from "../assets/image/training/pd565-annotations.png";
// @ts-expect-error false positive image import failure
import imgPD365 from "../assets/image/training/pd365-card.png";
// @ts-expect-error false positive image import failure
import imgPD565 from "../assets/image/training/pd565-card.png";
// @ts-expect-error false positive image import failure
import imgPNC370 from "../assets/image/training/pnc370-card.png";

type GraphQLResult = {
  markdownRemark: RadioTrainingMarkdownRemark;
};

const CaseStudyDetails: React.FC<PageProps<GraphQLResult>> = ({ data }) => {
  const {
    annotatedImageAlt,
    title,
    video1,
    video2,
    video3,
    annotatedImage,
  } = data.markdownRemark.frontmatter;
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{title} Training</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero
          image={annotatedImage}
          imageAlt={annotatedImageAlt}
          model={title}
          pdf="https://shopsafe.uk/downloads/pd565-User-Guide.pdf"
        />
        <Content1 video1={video1} video2={video2} video3={video3} />
        <Radios />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;

export const query = graphql`
  query TrainingDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        annotatedImageAlt
        title
        video1
        video2
        video3
        annotatedImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
