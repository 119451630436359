import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import { Container, Row, Col } from "react-bootstrap";
import { Section, Title } from "../../components/Core";
import PostCard from "../../components/PostCard";

// interface Props {
//   radios: {
//     to: string;
//     image: any;
//   }[];
// }
type Frontmatter = {
  frontmatter: {
    slug: string;
    type: string;
    title: string;
    previewImageAlt: string;
    previewImage: {
      childImageSharp: {
        fluid: {
          src: string | undefined;
        };
      };
    };
  };
};

const Radios = () => {
  const data = useStaticQuery(graphql`
    query RadioList {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            type: { eq: "radio-training" }
            promoted: { eq: true }
          }
        }
      ) {
        nodes {
          frontmatter {
            slug
            type
            title
            previewImageAlt
            previewImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const result = data.allMarkdownRemark.nodes;
  return (
    <Section py={0} className="position-relative">
      <Container>
        <Title textAlign="center" textColor="dark">
          Other Radios
        </Title>
        <Row className="align-items-center justify-content-center">
          {result.map((node: Frontmatter) => {
            const {
              title,
              slug,
              previewImage,
              previewImageAlt,
            } = node.frontmatter;
            return (
              <Col lg="4" md="3" sm="10" className="mb-4 mb-lg-5">
                <PostCard
                  img={previewImage.childImageSharp.fluid.src}
                  imgAlt={previewImageAlt}
                  readMore="Start Training"
                  to={`/training/${slug}`}
                  buttonText={title}
                ></PostCard>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};

export default Radios;
