import React from "react";
import YouTube from "react-youtube";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
// import { Box } from "../Core";

import { Section, Title } from "../../components/Core";
import { device } from "../../utils";

const VideoTitle = styled(Title)`
  font-size: 2rem;
  letter-spacing: 0px;
  margin-bottom: 10px;
`;
const VideoLabel = styled(Title)`
  font-size: 2.5rem;
  letter-spacing: 0px;
  margin-bottom: 10px;
  margin-right: 20px;
  border: #f04037 solid 4px;
  border-radius: 100%;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Center = styled.div`
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  margin-bottom: 80px;
  margin-top: 30px;
  width: 100%;
  height: 100%;
  min-height: 100px;
  /* position: absolute; */
`;

interface Props {
  video1?: string;
  video2: string;
  video3: string;
}

const Content1 = ({ video1, video2, video3 }: Props) => {
  return (
    <Section py={5}>
      <Container>
        <Title textAlign="center" textColor="dark" mb={5}>
          Training Videos
        </Title>

        {video1 && (
          <>
            <Row className="d-flex flex-column flex-md-row align-items-center  ml-1">
              <VideoLabel textColor="primary">1</VideoLabel>
              <VideoTitle textColor="dark">Whats in the box</VideoTitle>
            </Row>
            <Center className="embed-responsive embed-responsive-16by9">
              <YouTube videoId={video1} opts={{}} onReady={() => {}} />
            </Center>
          </>
        )}
        {video2 && (
          <>
            <Row className="d-flex flex-column flex-md-row align-items-center ml-1">
              <VideoLabel textColor="primary">2</VideoLabel>
              <VideoTitle textColor="dark" className="text-center">
                Signing On, Radio Checks &amp; Panic Alarms
              </VideoTitle>
            </Row>
            <Center className="embed-responsive embed-responsive-16by9">
              <YouTube videoId={video2} opts={{}} onReady={() => {}} />
            </Center>
          </>
        )}

        {video3 && (
          <>
            <Row className="d-flex flex-column flex-md-row align-items-center ml-1">
              <VideoLabel textColor="primary">3</VideoLabel>
              <VideoTitle textColor="dark" className="text-center">
                Making Radio Calls &amp; What To Say
              </VideoTitle>
            </Row>
            <Center className="embed-responsive embed-responsive-16by9">
              <YouTube videoId={video3} opts={{}} onReady={() => {}} />
            </Center>
          </>
        )}
      </Container>
    </Section>
  );
};

export default Content1;
